.form-control[disabled],
.form-control[readonly],
fieldset[disabled]
.form-control {
  background-color: #F5F5F5;
}
label {
  font-weight: normal;
}
[class^='select2'] {
  border-radius: 0px !important;
}
.select2-container .select2-selection--single {
  height: 36px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 34px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 32px;
}

.table-filters {
    input.datepicker {
      padding: 4px;
      border-radius: 0px;
      direction: ltr;
      border: 1px solid silver;
      margin-top: 5px;
    }
    .input-sm {
      height: 28px;
      width: 120px;
    }
    .select2-container .select2-selection--single {
      height: 28px;
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
      line-height: 28px;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
      height: 28px;
    }
}
.filters {
    label {
      display: block;
    }
}
