// Responsive
// --------------------------------------------------------------------
header .navbar-toggle {
  transition: margin 0.4s;
  -webkit-transition: margin 0.4s;
  /* Safari */
}
@media (max-width: 1200px) {
  #our-heads .person {
    width: 25%;
  }
  .person-photo {
    float: none;
    margin: auto;
  }
  footer .col-md-4.social,
  footer .col-md-4.text-right {
    text-align: center !important;
  }
  footer .col-md-4.text-right {
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) {
  #why-us .col-md-4 {
    clear: both;
  }
  header.compact .navbar #main-menu {
    margin-top: 10px;
  }
  #our-heads .person {
    width: 50% !important;
  }
  #contact-us .col-md-6 {
    clear: both;
  }
  footer .footer-logo{
    display: none;
  }
  footer #footer-menu{
    display: block;
  }
  footer .text-right{
    padding-top: 10px !important;
  }
  #hp-references .hp-reference{
    width: 30%;
  }
  body .slide{
    background-position: 0% 0% !important;
  }
}
@media (max-width: 991px) {
  header.compact .navbar-toggle {
    margin-top: 10px;
  }
  header.headroom--unpinned button.navbar-toggle {
    display: none;
  }
  #main-menu {
    background: #fff;
    margin: 0 -16px;
    margin-top: 0px !important;
  }
  #header.headroom--unpinned #main-menu {
    display: none !important;
  }
  .services #services {
    background: #14b5eb !important;
  }
  #our-heads .person-photo {
    float: none;
    margin: auto;
  }
  .btn-lg,
  .btn-group-lg > .btn {
    padding: 10px 20px;
    font-size: 18px !important;
  }
  #hp-references .hp-reference{
    width: 50%;
  }
  header .navbar-toggle{
    margin-right: 15px !important;
  }
  .navbar-brand{
    margin-left: 0px !important;
  }
  .bx-prev, .bx-next{
    display: none !important;
  }
  .portfolio-heading .portfolio-cats{
    position: relative;
    overflow: hidden;
  }
  .portfolio-heading .portfolio-cats a.portfolio-cat{
    float: left;
    font-size: 13px;
    padding: 5px 12px 3px;
    min-width: 85px;
  }
  #slider-reference{
    height: auto !important;
    margin-bottom: 30px !important;
    padding-bottom: 30px !important;
  }
  #slider-reference .slide{
    height: auto !important;
    padding-bottom: 30px !important;
  }
}
@media (max-width: 500px) {
  #hp-references .hp-reference{
    width: 100%;
  }
  .slide h3{
    font-size: 27px !important;
  }
  .slide h2{
    font-size: 37px !important;
  }
  .name{
    max-width: 100% !important;
  }
}
@media (max-width: 450px) {
  .reference .single-reference{
    margin-bottom: 220px !important;
    overflow: visible;
  }
  .reference .single-reference .info{
    top: auto !important;
    height: 220px !important;;
    bottom: -220px !important;
    opacity: 1 !important;
    filter: alpha(opacity=100);
  }
  .reference .single-reference:hover img.logo {
    opacity: 1;
  }
}
@media (max-width: 991px) {
  header .navbar #main-menu ul li a{
    border-radius: 0px !important;
  }
  .content-block {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}
@media (max-width: 991px) {
  #process .process-icon{
    display: none;
  }
  #openings > .container{
    background: transparent !important;
  }
  .portfolio-cats{
    text-align: left !important;
  }
  .reference .single-reference{
    width: 50% !important;
    height: 250px !important;
    line-height: 250px !important;
    border-bottom: 1px solid #f0f0f0 !important;
  }
  .reference .single-reference:nth-child(odd){
    border-left: 0 !important;
  }
  .reference .single-reference:nth-child(even){
    border-right: 0 !important;
  }
  .references #content hr{
    display: none;
  }
  .references .screen{
    margin-bottom: 30px;
  }
}
@media (max-width: 450px) {
  .reference .single-reference{
    width: 100% !important;
    margin: 0px !important;
  }
  .reference .single-reference .info{
    display: none !important;
  }
  .contact .contact-info{
    font-size: 80%;
  }
  .contact .person-photo {
    width: 70px;
    height: 70px;
  }
  .contact .main-phone, .contact .main-email{
    font-size: 80%;
  }
  .portfolio-heading .portfolio-cats a.portfolio-cat{
    width: 46%;
  }
  .portfolio-heading .portfolio-cats a.portfolio-cat:nth-child(even){
    float: right;
  }
  #our-heads .person-photo, .contact .person-photo {
    width: 150px;
    height: 150px;
  }
}
@media (max-width: 550px) {
  #slider .slide .name h1, .slider .slide .name h1{
    font-size: 33px !important;
  }
  #slider .slide .name h3, .slider .slide .name h3{
    font-size: 20px !important;
  }
  .title-block p{
    font-size: 17px !important;
  }
  .title-block h1{
    font-size: 35px !important;
  }
  .btn.btn-lg{
    padding: 15px 25px !important;
    min-width: 230px !important;
  }
}
img.desaturate {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: grayscale(100%);
}
.name {
  max-width: 100%;
}
.bx-viewport {
  height: inherit !important;
}