/*
 * Designeo Portal Layout
 *
 * Copyright 2015 Designeo Creative s.r.o., all rights reserved
 * --------------------------------------------------------------------
*/


// Main
// --------------------------------------------------------------------
body {
  padding-top: 60px;
}
h1, h2 {
  text-transform: uppercase;
}
* {
  outline: none !important;
}

// Header
// --------------------------------------------------------------------

header {
	position: fixed;
	top: 0;
	height: 115px;
	width: 100%;
	background: rgba(255, 255, 255, 1);
	border-bottom: 1px solid #f0f0f0;
	z-index: 999999;
	.transition (all 300ms linear);

	.navbar .container {
		position: relative;
	}
	.navbar-toggle {
		margin-top: 40px;
		margin-right: 0px;
		border-color: @designeo-blue;
		span {
			background: @designeo-blue;
		}
	}
	ul.lang-switch {
		position: absolute;
		right: 0px;
		padding: 0;
		top: -1px;
		list-style-type: none;
		.transition (all 300ms linear);
		li {
			display: inline-block;
			text-align: center;
			margin: 0;
			padding: 0;
			width: 30px;
			a {
				text-decoration: none;
				display: block;
				line-height: 30px;
				width: 30px;
				height: 30px;
				font-size: 13px;
				color: @designeo-blue;
				&:hover {
					background: @designeo-blue;
					color: #fff;
				}
			}
			&.active a {
				background: @designeo-blue;
				color: #fff;
			}
		}
	}
	.navbar-brand {
		margin-left: -15px;
		padding: 0;
		img {
			margin: 30px 15px;
			height: 52px;
			.transition (all 300ms linear);
		}
	}
	.navbar {
		margin-bottom: 0px;
		#main-menu {
			.transition (all 300ms linear);
			margin-top: 40px;
			ul {
				li {
					a{padding: 7px 17px;
						text-transform: uppercase;
						font-weight: bold;
						border-radius: 4px;
						font-size: 14px;
						color: @designeo-gray;
						&:hover {
							background: @designeo-red;
							color: #fff !important;					
						}
					}
					&.active a {
						color: @designeo-red;
					}
				}
			}
		}
	}
}

header.compact {
	height: 60px;
	.transition (all 500ms linear);
	
	.navbar-brand {
		img {
			margin-top: 8px;
			height: 38px;
			.transition (all 500ms linear);	
		}
	}
	.navbar {
		#main-menu {
			margin-top: 10px;
		}
	}
	.lang-switch {
		display: none;
		.transition (all 500ms linear);
	}
}

.headroom {
    -webkit-transition: transform 200ms linear;
    -moz-transition: transform 200ms linear;
}
.headroom--pinned {
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
}
.headroom--unpinned {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
}


// Footer
// --------------------------------------------------------------------

footer {
	min-height: 50px;
	background: @white;
	font-size: 13px;
	a {
		color: #ffffff;
		&:hover {
			color: #ffffff;
		}
	}
	.row {
		& > div {
			padding-top: 20px;
		}
	}
}

// Content
// --------------------------------------------------------------------

.content-block {
	padding-top: 50px;
	padding-bottom: 50px;
}
#content {
	padding-bottom: 40px;
}

