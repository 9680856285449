
.projects {
  #project-tabs {

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    .flex-spacer {
      flex-grow: 1;
    }

    margin: 25px 0;
    border: none;

    li {
      display: inline-block;
      float: none;
      flex-grow: 0;
    }

    li a {
      font-size: 24px;
      font-weight: bold;
      border-radius: 0px;
      padding: 15px 20px;
      &:hover {
        border: 1px solid transparent;
      }
    }
    li.active > a {
      background: @designeo-blue;
      border: 1px solid @designeo-blue;
      color: @white;
    }

  }
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > th {
    background: #eeeeee;
  }
  .subproject .hover-icon-parent a {
    padding-left: 50px;
  }

}

#project_usersOnProject {
  padding: 10px;

  .form-group {
    font-size: 11px;
    margin-bottom: 0;
  }
}


.task-detail-modal {
  z-index: 1000000;
  top: 50px;
  font-size: 13px;
  color: @designeo-gray;

  table {
    margin-bottom: 0px;
    white-space: nowrap;
  }

  .modal-body {
    padding: 30px;
  }

  @media (min-width: 768px) {
    .modal-dialog {
      width: 750px;
    }
  }
}

.project-detail {
  .row {
    margin-bottom: 30px;
  }
  .timeline {
    margin-left: 0;
    margin-right: 0;
    .frame {
      padding: 15px;
      color: @white;
    }
    h4 {
      color: @white;
      margin: 0;
    }
    .spec {
      background: @designeo-orange;
    }
    .devel {
      background: @designeo-blue;
    }
    .test {
      background: @designeo-red;
    }
    .support {
      background: @designeo-dark-blue;
    }
  }
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > th {
    background: #eeeeee;
  }
  .worker {
    float:left;
    padding: 0px;
    margin: 10px;
    min-height: 120px;
    img {
      border-radius: 50px;
    }
  }
  .worker-no-avatar {
    float:left;
    padding: 0px;
    margin: 10px;
    min-height: 80px;
    img {
      border-radius: 50px;
    }
  }
}

// TIMELINE
.timeline.projects {
  margin-top: 60px;

  .day-week-7,
  .day-end-day-week-7 {
    border-right: 1px darkgrey solid;
  }

  .day-start-day-today {
    border-left: 2px darken(darkgrey, 10) solid!important;
  }

  .stage-no-action {
    &.day-end-day-weekend,
    &.day-end-day-holiday {
      background-color: #eee;
    }

    &.day-end-day-today {
      background-color: darken(#eee, 10);
    }
  }

  .cell-stage {
    border-left:0;
    border-right: 0;
    &.day-end-day-weekend,
    &.day-end-day-holiday {
      color: transparent;
    }
  }

  @timeline-border: darken(darkgrey, 10);
  @timeline-text: darken(black, 10);

  .timeline-stage-cell(@color; @stage) {
    .stage-@{stage} {
      background-color: @color;
      color: white;
      &.day-end-day-weekend,
      &.day-end-day-holiday{
        background-color: lighten(@color, 25);
      }
      &.day-start-day-today {
        background-color: darken(@color, 10);
        border-right:1px darken(@color, 10) solid;
         & + .stage-@{stage} {
           color: transparent;
         }
      }
    }
  }
  .cell-project, .cell-project-type, .cell-stage {
    text-align: left;
  }
  .cell-project, .cell-project-type {
    //padding-left: 15px;
    //padding-right: 15px;

  }
  .cell-project-type {
    background-color: #eee;
    border-left: 0;
    border-right: 0;

  }

  .stage-no-action {
    color: transparent;
  }

  .timeline-stage-cell(@designeo-orange, spec);
  .timeline-stage-cell(@designeo-blue, dev);
  .timeline-stage-cell(@designeo-dark-blue, test);
  .timeline-stage-cell(@designeo-red, go-live);
  .timeline-stage-cell(@gray, support);

  // Deadlines on weekends should be not be dimmed
  .stage-go-live {
    color: white !important;
    background-color: @designeo-red !important;
  }

  .stage-go-live.strict {
    background-color: darken(@designeo-red, 20%) !important;
  }
}

.clients {
  .client-card {
    margin-bottom: 30px;
    height: 200px;
  }
  .logo {
    height: 30px;
    width: 60px;
    margin:0 auto;
  }
  .logo img {
    max-height: 30px;
    max-width: 60px;
  }
  td {
    vertical-align: middle !important;
  }
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > th {
    background: #eeeeee;
  }
}

.text-danger.text-danger-extreme {
  font-weight: bold;
}

/* Sortable */
table[data-sortable] th.text-right,
table[data-sortable] td.text-right {
  text-align: right;
}
table[data-sortable] th.text-center,
table[data-sortable] td.text-center {
  text-align: center;
}

table[data-sortable] th.text-right:after,
table[data-sortable] th.text-center:after,{
  content: normal;
}
table[data-sortable] th[data-sorted-direction="descending"]:after {
  border-top-color: black;
}
table[data-sortable] th[data-sorted-direction="ascending"]:after {
  border-bottom-color: black;
}

.project-detail-support {
  .support-budget {
    h2 {
      text-transform: none;
    }
  }
}

.project-support {
  tr {
    th {

      &.table-subsection {
        font-size: 15px;
      }
    }

    td {

    }
  }

  table.table-detail-support {
    border-left: 1px darkgrey solid;
    border-bottom: 1px darkgrey solid;
    margin-bottom: 0;

    &.detail-table-state-open {
      margin-top: 25px;
      margin-bottom: 25px;
    }


    & [data-detail-table-toggle] {
      cursor: pointer;
    }

    tbody tr {
      &.table-detail-title {
        th {
          font-weight: normal;
          background-color: #f8f8f8;

          &:first-child {
            padding-left: 15px;
          }
        }
      }

      &.table-detail-item {
        & > td:first-child {
          padding-left: 30px;
        }
      }
    }
  }
}

.page-invalid-activities {
  table.table {
    &.no-bottom-spacing {
      margin-bottom: 0;
    }

    &.detail-table-state-open {
      margin-bottom: 25px;

      tr:last-child {
        border-bottom: 2px #f8f8f8 solid;
      }
    }

    .row-tab {
      border-top: none;
    }

    th {
      background-color: #f8f8f8;
    }
  }
}

.tree {
  .children {
    display: none;
  }
  .lvl0 {
    font-weight: bold;
  }

  .lvl1 {
    margin-left: 20px;
    color: grey;
  }

  .lvl2 {
    margin-left: 40px;
    color: grey;
  }

  .lvl3 {
    margin-left: 60px;
    color: grey;
  }

  .lvl4 {
    margin-left: 80px;
    color: grey;
  }
}
