.timeline {

  border: 0;

  thead th{
    border: 1px solid #e0e0e0;
    min-width: 36px;
  }

  td {
    border: 1px solid #e0e0e0;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;
    text-align: center;
    position: relative;
    white-space: nowrap;
    height: 30px;

    a.action-add {
      display: block;
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      left: 0;
      .fa {
        display: none;
      }
      &:hover {
        text-decoration: none;
        background: rgba(0, 0, 0, 0.3);
      }
      &:hover .fa {
        display: block;
        font-size: 22px;
        padding-top: 2px;
        text-decoration: none;
        color: white;
      }
    }
    a.action-edit {
      display: block;
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      right: 0;
      .fa {
        display: none;
      }
      &:hover {
        text-decoration: none;
        background: rgba(0, 0, 0, 0.3);
      }
      &:hover .fa {
        display: block;
        font-size: 22px;
        padding-top: 2px;
        text-decoration: none;
        color: white;
      }
    }
    a.action-full {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .fa {
        display: none;
      }
      &:hover {
        text-decoration: none;
        background: rgba(0, 0, 0, 0.3);
      }
      &:hover .fa {
        display: block;
        font-size: 22px;
        padding-top: 2px;
        text-decoration: none;
        color: white;
      }
    }
  }

  th {
    color: black;
    font-weight: normal!important;
    font-size: 13px;
    padding: 5px;

    .day-weekend,
    .day-holiday {
      background-color: #eee !important;
      color: silver !important;
    }
    .day-week-7 {
      border-right: 1px darkgrey solid;
    }
  }

  tr.timeline tr.timeline-header th {
    color: #000;
  }

  th.day-today {
    background-color: @designeo-blue !important;
    color: white !important;
  }
}

.timeline-navbar {
  min-height: 60px;
  border-bottom: 1px solid #f0f0f0;
  background: #fbfbfb;
  position: fixed;
  width: 100%;
  z-index: 1000;

  .timeline-top-buttons,
  .timeline-filter {
    margin-top: 10px;
  }
  h2 {
    margin-top: 10px;
  }
}

.no-container {
  margin: 60px 0 0 0px;
}


.DTFC_LeftBodyLiner{
  overflow-y: hidden!important;
}

.DTFC_Cloned tr{
  background: #ffffff;
}

.DTFC_LeftBodyWrapper{
  border-right: 1px darkgrey solid;
}

table.dataTable{
  margin: 0!important;
}
