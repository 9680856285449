.app {
  position: relative;
  margin-bottom: 30px;
  padding: 20px 0;
  margin: 0;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  height: 175px;
  &:hover {
    .app-icon {
      color: @white;
      background: @designeo-red;
    }
    .fa-external-link {
      display: block;
    }
  }
  &.app-external {
  }
  .app-icon {
    background: @designeo-gray;
    border-radius: 5px;
    width: 100px;
    height: 100px;
    color: white;
    font-size: 60px;
    padding: 10px;
    margin: 0 auto;
    text-align: center;
  }
  .app-icon-external {
    background: lighten(@designeo-gray, 30%);
  }
  .app-icon-disabled {
    background: none;
    color: @designeo-gray;
  }
  h4 {
    font-weight: normal;
  }
  .fa-external-link {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
.external-apps {
  border-top: none;
}

.dashboard-box {
  position: relative;
  margin-bottom: 30px;
  padding: 20px 0;
  margin: 0;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  &:hover {
    .app-icon {
      color: @white;
      background: @designeo-red;
    }
    .fa-external-link {
      display: block;
    }
  }
  &.app-external {
  }
  .app-icon {
    background: @designeo-gray;
    border-radius: 5px;
    width: 100px;
    height: 100px;
    color: white;
    font-size: 60px;
    padding: 10px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
  }
  .app-icon-external {
    background: lighten(@designeo-gray, 30%);
  }
  .app-icon-disabled {
    background: none;
    color: @designeo-gray;
  }
  h4 {
    font-weight: normal;
  }
  .fa-external-link {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.dashboard-hr {
  margin-top:50px;
  padding-top:30px;
  border-top:1px solid silver;
}