#rules {
  .point {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  h3 {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-top: 80px;
  }
  .rules-nav-sidebar {
    width: 300px;
    float: left;
    ul {
      position: fixed;
      width: 300px;
    }
  }
  .rules-content {
    border-left: 1px solid #eee;
    padding-left: 40px;
    margin-left: 300px;
  }
  .rules-scheme-image {
    margin: 20px 0;
    display: inline-block;
    img {
      width: 1000px;
      border: 1px solid #eee;
      padding: 10px;
      margin: 10px 0;
    }
  }
}


@media (max-width: 991px) {
  #rules {
    .rules-nav-sidebar {
      display: none;
    }
    .rules-content {
      border-left: none;
      padding-left: 20px;
      margin-left: 0px;
    }
    .rules-scheme-image {
      img {
        width: 100%;
      }
    }
  }
}