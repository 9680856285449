.attendance {

  .cell {
    color: white;
    &-user {
      text-align: left;
      //padding-left: 10px;
      //padding-right: 10px;
      border-right: 1px solid #e0e0e0;
      &-group {

        background-color: #eee;
        &-holder {
          border-right: 0;
          border-left: 0
        }
      }
    }
  }

  .day-weekend,
  .day-holiday {
    background-color: #eee;
    color: silver;
  }

  @today-border-color: darken(@designeo-blue, 15%);

  .day-today {
    border-left: 3px @today-border-color solid;
    border-right: 3px @today-border-color solid;
  }

  th.day-today {
    background-color: @today-border-color !important;
    color: white !important;
  }

  td {
    &.type-entry {
      color: white;
    }

    &.type-1 {
      background-color: @designeo-green; // pracuje
    }
    /*&.type-1.cell-less-8 {
      background-color: lighten(@designeo-green, 10%);
    }
    &.type-1.cell-less-4 {
      background-color: lighten(@designeo-green, 10%);
    }*/
    &.type-1.cell-zero {
      background-color: @designeo-orange;
    }
    &.type-2 {
      background-color: lighten(@designeo-green, 20%); // homeoffice
    }
    &.type-3 {
      background-color: lighten(@designeo-gray, 40%); // volno - nepracuji dle planu
    }
    &.type-4 {
      background-color: darken(@designeo-orange, 10%); // dovolena
    }
    &.type-5 {
      background-color: darken(@designeo-orange, 10%); // nemoc - orig. lighten(@designeo-orange, 15%)
    }
    &.type-6 {
      background-color: darken(@designeo-orange, 10%); // volno
    }
  }
}

