.sap-changelog pre {
  background: #fff;
  border: none;
  font-size: 10px;
}

.hover-icon-parent {
  .hover-icon {
    visibility: hidden;
  }
  &:hover {
    .hover-icon {
      visibility: visible;
    }
  }
}

.table-smaller {
  font-size: 85%;
}
