.avatar {
  border-radius: 50px;
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.avatar img {
  max-width: 100%;
}
.avatar-lg {
  border-radius: 150px;
  width: 150px;
  height: 150px;
}
.avatar-xs {
  border-radius: 60px;
  width: 60px;
  height: 60px;
}
.avatar-md {
  border-radius: 100px;
  width: 100px;
  height: 100px;
}
.avatar-sm {
  border-radius: 30px;
  width: 30px;
  height: 30px;
}
.avatar-center {
  margin: 0 auto;
}


.user-card {
  margin-bottom: 30px;
  height: 225px;

  &:hover {
    img {
      opacity: 0.7;
    }
  }
}

.no-active-user {
  opacity: 0.3;
}

.table.users-list td {
  vertical-align: middle;
}

.devices {
  font-size: 24px;
  color: silver;

  .online {
    text-shadow: 0 0 4px fade(@brand-primary, 70%);
    color: @brand-primary;
  }
}


.user-card {
  &--with-online-status {
    .avatar {
      border: 3px solid silver;
    }

    &.online .avatar {
      border-color: @brand-primary;
    }
  }
}

.not-employed {
  a {
    color: #ccc;
  }
}

.user-project .checkbox {
  margin: 0;
}
