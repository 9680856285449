.error-body {
    background-color: #13b5ea;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    color: #f7f7f7;
}

.error-image-404 {
    background-image: url(/img/404page.jpg);
}

.error-image-500 {
    background-image: url(/img/500page.jpg);
}

.error-h1 {
    text-align: center;
}
